import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { useForm } from "react-hook-form";
import Loader from "react-loader-spinner";
import { navigate } from "gatsby";

// css
import * as styles from "./LoginForm.module.css";

const LoginForm = props => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isValid, isSubmitted },
  } = useForm({ mode: "all" });

  // Login Form -> Call Submit button click
  const onSubmit = data => {
    props.getLoginCredentialsData(data);
  };

  // Forgot Password -> Call Forgot Password click
  const onForgetPassSubmit = () => {
    const emailVal = watch("email");
    props.forgetPassword(emailVal);
  };

  return (
    <div className="container twinContainer">
      <div className="row">
        <div className={`col-12 ${styles.loginFormSec}`}>
          {/* Logo */}
          <div
            className={styles.loginFormSec__logo}
            role="presentation"
            onClick={() => navigate("/")}
          >
            <StaticImage
              src="../../../images/twin-logo/twinLogo1_2024.png"
              alt=""
              className="img-fluid"
              placeholder="blurred"
              quality={90}
            />
          </div>
          {/* Heading */}
          <div className={styles.loginFormSec__para}>
            <h1>Sign in</h1>
          </div>
          {/* Form */}
          <div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className={`row ${styles.loginFormSec__form}`}>
                <div
                  className={`col-12 form-group ${styles.loginFormSec__formInp}`}
                >
                  <label htmlFor="email">Email*</label>
                  <input
                    className={
                      errors.email ? "form-control is-invalid" : "form-control"
                    }
                    id="email"
                    placeholder="Enter your email"
                    {...register("email", {
                      required: "Please enter valid email address",
                      pattern: {
                        value:
                          /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i, // eslint-disable-line no-useless-escape
                        message: "Entered value does not match email format",
                      },
                    })}
                    type="email"
                  />
                  {errors.email && (
                    <span role="alert" className="invalid-feedback">
                      {errors.email.message}
                    </span>
                  )}
                </div>

                <div
                  className={`col-12 form-group mt-3 ${styles.loginFormSec__formInp}`}
                >
                  <label htmlFor="password">Password*</label>
                  <input
                    className={
                      errors.password
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    id="password"
                    placeholder="Enter your password"
                    {...register("password", {
                      required: "Please enter your password",
                    })}
                    type="password"
                  />
                  {errors.password && (
                    <span className="invalid-feedback">
                      {errors.password.message}
                    </span>
                  )}
                </div>
              </div>
              <div
                className={styles.loginFormSec__forgetPass}
                onClick={onForgetPassSubmit}
                aria-hidden="true"
              >
                <p>Forgot Password</p>
              </div>
              <div className={styles.loginFormSec__cta}>
                <button type="submit">
                  <span
                    className={props.loading && styles.loginFormSec__cta__txt}
                  >
                    Sign In
                  </span>
                  <span className={styles.loginFormSec__cta__loader}>
                    <Loader
                      type="TailSpin"
                      color="#FFF"
                      height={30}
                      width={30}
                      radius={1}
                      visible={props.loading}
                    />
                  </span>
                </button>
                <span
                  className={`d-block errorclass text-danger ${styles.loginFormSec__errorTxt}`}
                >
                  {!isValid &&
                    isSubmitted &&
                    "Please fill all the fields correctly"}
                </span>
                {props.serverError && (
                  <span
                    className={`d-block text-danger ${styles.loginFormSec__errorTxt}`}
                  >
                    {props.serverError}
                  </span>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
