import React, { useState, useEffect } from "react";
import { StaticImage } from "gatsby-plugin-image";
import axios from "axios";
import { navigate } from "gatsby";

// seo
import Seo from "../components/Seo";

// config
import config from "../config/index";

// utils
import { uniqueRandomNumber, authBase64Encoding } from "../utils";

// Local Storage
import localStorage from "../utils/LocalStorageService";

// components
import LoginForm from "../components/CsvUpload/LoginForm/LoginForm";
import SuccessToast from "../components/Toasts/SuccessToast";

// styles
import * as styles from "../components/CsvUpload/CsvUpload.module.css";

const CsvLoginPage = () => {
  const [showSuccessToast, setShowSuccessToast] = useState(false); // show toast message on forgot password click.
  const [loading, setLoading] = useState(false);
  const [serverError, setServerError] = useState("");

  useEffect(() => {
    const getUserDeviceId = localStorage.getValue("DeviceId");

    // Save unique device id of the user into local storage
    if (!getUserDeviceId) {
      const uniqueDeviceId = uniqueRandomNumber();
      localStorage.setValue("DeviceId", { id: uniqueDeviceId });
    }
  }, []);

  // To call forget password
  const forgetPassword = async id => {
    if (id) {
      try {
        setServerError("");
        let forgotPassOptions = {
          method: "post",
          url: config.ICAP_PLATFORM_URL + "/api/client/auth/passwordReset",
          headers: {
            "Content-Type": "text/plain",
          },
          data: id,
        };
        await axios(forgotPassOptions);
        setShowSuccessToast(true);
      } catch (error) {
        console.log("Error in icap forgot password api:", error);
        if (error.response.status === 401) {
          setServerError(error.response.data.message);
        } else {
          setServerError(
            "Something went wrong. Please try again after sometime"
          );
        }
      }
    } else {
      setServerError("Please enter your email id above")
      return;
    }
  };

  const getLoginCredentialsData = async value => {
    const encodedAuthString = authBase64Encoding(value.email, value.password);
    const getUserDeviceId = localStorage.getValue("DeviceId");

    const loginOptions = {
      method: "post",
      url: config.ICAP_PLATFORM_URL + "/api/jwt/authenticate",
      headers: {
        Authorization: `Basic ${encodedAuthString}`,
        "Content-Type": "application/json",
      },
      data: {
        deviceUUID: getUserDeviceId.id.toString(),
      },
    };

    try {
      setLoading(true);

      const loginApiResponse = await axios(loginOptions);
      // console.log("Aman singh", loginApiResponse);
      localStorage.setToken(loginApiResponse.data);
      navigate("/csv-upload");

      setLoading(false);
      setServerError("");
    } catch (error) {
      setLoading(true);
      console.error("Error in icap login api:", error);
      if (error.response.status === 401) {
        setServerError(error.response.data.message);
      } else {
        setServerError("Something went wrong. Please try again after sometime");
      }
      setLoading(false);
    }
  };

  return (
    <div className={`bgWhiteColor d-flex ${styles.csvLoginPage}`}>
      <Seo
        title="Reverse Diabetes @ Twin | Book an appointment with India's leading Doctors"
        description="Is Diabetes Reversal possible? Book a Personalised Video Consultation with Top Diabetologists & get all your Diabetes-related queries answered."
      />
      <LoginForm
        loading={loading}
        getLoginCredentialsData={getLoginCredentialsData}
        serverError={serverError}
        forgetPassword={forgetPassword}
      />
      <div className={styles.csvLoginPage__bannerImage}>
        <StaticImage
          src="../images/csvUpload/csv_upload_login_new.png"
          alt=""
          className="img-fluid"
          placeholder="blurred"
          quality={90}
        />
      </div>

      {/* Toast components */}
      <SuccessToast
        position="top-center"
        showSuccessToast={showSuccessToast}
        delay={7000}
        autohide={true}
        text="You will receive an email message with instructions to reset your password, as long as the email address matches an account in our system."
        onClose={() => setShowSuccessToast(false)}
      />
    </div>
  );
};

export default CsvLoginPage;
