// extracted by mini-css-extract-plugin
export var bannerImage = "LoginForm-module--bannerImage--jfG1I";
export var loginFormSec = "LoginForm-module--loginFormSec--mAkqc";
export var loginFormSec__cta = "LoginForm-module--loginFormSec__cta--fCGR7";
export var loginFormSec__cta__loader = "LoginForm-module--loginFormSec__cta__loader--k219w";
export var loginFormSec__cta__txt = "LoginForm-module--loginFormSec__cta__txt--z4Q6i";
export var loginFormSec__errorTxt = "LoginForm-module--loginFormSec__errorTxt--hsBpq";
export var loginFormSec__forgetPass = "LoginForm-module--loginFormSec__forgetPass--kTUSU";
export var loginFormSec__form = "LoginForm-module--loginFormSec__form--69Vyz";
export var loginFormSec__formInp = "LoginForm-module--loginFormSec__formInp--N8+Q1";
export var loginFormSec__logo = "LoginForm-module--loginFormSec__logo--AQAGb";
export var loginFormSec__para = "LoginForm-module--loginFormSec__para--PKWIa";